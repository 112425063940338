import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Footer, WhatsApp } from '~/components'
import * as st from '~/assets/styl/PrivacyPolicy.module.styl'

const PrivacyPolicy = ({ location }: PageProps) => (
  <>
    <Head
      location={location}
      title={`Política de privacidade - ${process.env.GATSBY_SITE_NAME}`}
    />
    <section className={st.core}>
      <div className={st.container}>
        <h3>Política de privacidade</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus
          purus ac nisi aliquet, vel pharetra odio pretium. Morbi facilisis leo
          et nibh iaculis volutpat. Etiam eu nibh dolor. Nunc at ante
          sollicitudin, viverra metus ac, fringilla ex. Cras efficitur pretium
          lacinia. Donec egestas vehicula dapibus. Duis fermentum ultrices ante
          eu mollis.
          <br />
          <br />
          Ut quis nunc erat. Vivamus lobortis risus at enim accumsan, a varius
          ante finibus. Nulla facilisi. Morbi sit amet enim lacinia, ornare nunc
          a, ornare libero. Cras fermentum feugiat urna sed malesuada.
          Suspendisse vestibulum condimentum sapien, a fringilla nisi dapibus
          quis. Quisque mollis massa velit, volutpat posuere nisl interdum nec.
          <br />
          <br />
          Vestibulum odio tellus, hendrerit in tincidunt id, commodo vel magna.
          Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut erat
          nulla, tristique in lorem et, congue viverra orci. In tempor arcu et
          convallis lobortis. Sed vehicula vitae magna nec luctus. Nulla
          ultrices diam vel libero congue gravida. Cras interdum nisl nec dui
          accumsan euismod. Donec in enim in risus scelerisque condimentum.
          Curabitur mauris urna, rutrum vel odio vel, laoreet finibus odio.
          Vestibulum mattis ultricies sem ac tincidunt. Duis ac felis dui.
          <br />
          <br />
          Maecenas ac diam sollicitudin, placerat tortor at, blandit urna.
          Aliquam ac tortor ut nisl pharetra malesuada eget non justo. Nulla a
          nunc ex. Vivamus consectetur nulla eu pretium convallis. Duis purus
          velit, efficitur euismod velit nec, porta facilisis nisl. Mauris neque
          sapien, porta non hendrerit id, sollicitudin eu felis. Mauris sit amet
          lacus rhoncus, iaculis ligula sit amet, viverra ligula. Donec vitae
          tristique augue. Integer commodo, purus eget congue egestas, justo ex
          pellentesque ante, a congue nulla ante efficitur orci. Curabitur
          aliquam erat id dolor semper blandit. Integer semper faucibus viverra.
          Fusce nec lectus a lacus mollis mattis vitae vitae lorem. Donec
          lobortis vestibulum nunc et lacinia. Duis sem ex, pellentesque at
          bibendum ut, bibendum tristique diam. Mauris euismod consequat dolor
          quis sollicitudin. Donec pretium quis orci a sollicitudin.
          <br />
          <br />
          Nullam in quam magna. Etiam facilisis felis sed mauris semper viverra.
          Vivamus porta vulputate tellus, eu placerat nibh accumsan elementum.
          Quisque ultrices tincidunt elit, vitae efficitur velit facilisis non.
          Suspendisse malesuada, enim id viverra tincidunt, velit lacus
          tincidunt ex, non lobortis mauris augue in odio. Aenean blandit felis
          et tempus varius. Vestibulum semper placerat lectus quis lobortis.
          Praesent pulvinar enim turpis, quis luctus purus sollicitudin vitae.
          Aenean leo ante, semper in aliquet lacinia, vehicula id diam. Ut et
          eros ac nibh finibus ultricies vel id purus. Pellentesque sed tellus
          ac turpis porttitor porttitor. Suspendisse non odio nisl. Sed ornare
          sapien non eros lacinia dapibus. Nulla mauris metus, feugiat sit amet
          aliquam in, tincidunt quis est.
          <br />
          <br />
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus
          purus ac nisi aliquet, vel pharetra odio pretium. Morbi facilisis leo
          et nibh iaculis volutpat. Etiam eu nibh dolor. Nunc at ante
          sollicitudin, viverra metus ac, fringilla ex. Cras efficitur pretium
          lacinia. Donec egestas vehicula dapibus. Duis fermentum ultrices ante
          eu mollis.
          <br />
          <br />
          Ut quis nunc erat. Vivamus lobortis risus at enim accumsan, a varius
          ante finibus. Nulla facilisi. Morbi sit amet enim lacinia, ornare nunc
          a, ornare libero. Cras fermentum feugiat urna sed malesuada.
          Suspendisse vestibulum condimentum sapien, a fringilla nisi dapibus
          quis. Quisque mollis massa velit, volutpat posuere nisl interdum nec.
          <br />
          <br />
          Vestibulum odio tellus, hendrerit in tincidunt id, commodo vel magna.
          Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut erat
          nulla, tristique in lorem et, congue viverra orci. In tempor arcu et
          convallis lobortis. Sed vehicula vitae magna nec luctus. Nulla
          ultrices diam vel libero congue gravida. Cras interdum nisl nec dui
          accumsan euismod. Donec in enim in risus scelerisque condimentum.
          Curabitur mauris urna, rutrum vel odio vel, laoreet finibus odio.
          Vestibulum mattis ultricies sem ac tincidunt. Duis ac felis dui.
          <br />
          <br />
          Maecenas ac diam sollicitudin, placerat tortor at, blandit urna.
          Aliquam ac tortor ut nisl pharetra malesuada eget non justo. Nulla a
          nunc ex. Vivamus consectetur nulla eu pretium convallis. Duis purus
          velit, efficitur euismod velit nec, porta facilisis nisl. Mauris neque
          sapien, porta non hendrerit id, sollicitudin eu felis. Mauris sit amet
          lacus rhoncus, iaculis ligula sit amet, viverra ligula. Donec vitae
          tristique augue. Integer commodo, purus eget congue egestas, justo ex
          pellentesque ante, a congue nulla ante efficitur orci. Curabitur
          aliquam erat id dolor semper blandit. Integer semper faucibus viverra.
          Fusce nec lectus a lacus mollis mattis vitae vitae lorem. Donec
          lobortis vestibulum nunc et lacinia. Duis sem ex, pellentesque at
          bibendum ut, bibendum tristique diam. Mauris euismod consequat dolor
          quis sollicitudin. Donec pretium quis orci a sollicitudin.
          <br />
          <br />
          Nullam in quam magna. Etiam facilisis felis sed mauris semper viverra.
          Vivamus porta vulputate tellus, eu placerat nibh accumsan elementum.
          Quisque ultrices tincidunt elit, vitae efficitur velit facilisis non.
          Suspendisse malesuada, enim id viverra tincidunt, velit lacus
          tincidunt ex, non lobortis mauris augue in odio. Aenean blandit felis
          et tempus varius. Vestibulum semper placerat lectus quis lobortis.
          Praesent pulvinar enim turpis, quis luctus purus sollicitudin vitae.
          Aenean leo ante, semper in aliquet lacinia, vehicula id diam. Ut et
          eros ac nibh finibus ultricies vel id purus. Pellentesque sed tellus
          ac turpis porttitor porttitor. Suspendisse non odio nisl. Sed ornare
          sapien non eros lacinia dapibus. Nulla mauris metus, feugiat sit amet
          aliquam in, tincidunt quis est.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus
          purus ac nisi aliquet, vel pharetra odio pretium. Morbi facilisis leo
          et nibh iaculis volutpat. Etiam eu nibh dolor. Nunc at ante
          sollicitudin, viverra metus ac, fringilla ex. Cras efficitur pretium
          lacinia. Donec egestas vehicula dapibus. Duis fermentum ultrices ante
          eu mollis.
          <br />
          <br />
          Ut quis nunc erat. Vivamus lobortis risus at enim accumsan, a varius
          ante finibus. Nulla facilisi. Morbi sit amet enim lacinia, ornare nunc
          a, ornare libero. Cras fermentum feugiat urna sed malesuada.
          Suspendisse vestibulum condimentum sapien, a fringilla nisi dapibus
          quis. Quisque mollis massa velit, volutpat posuere nisl interdum nec.
          <br />
          <br />
          Vestibulum odio tellus, hendrerit in tincidunt id, commodo vel magna.
          Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut erat
          nulla, tristique in lorem et, congue viverra orci. In tempor arcu et
          convallis lobortis. Sed vehicula vitae magna nec luctus. Nulla
          ultrices diam vel libero congue gravida. Cras interdum nisl nec dui
          accumsan euismod. Donec in enim in risus scelerisque condimentum.
          Curabitur mauris urna, rutrum vel odio vel, laoreet finibus odio.
          Vestibulum mattis ultricies sem ac tincidunt. Duis ac felis dui.
          <br />
          <br />
          Maecenas ac diam sollicitudin, placerat tortor at, blandit urna.
          Aliquam ac tortor ut nisl pharetra malesuada eget non justo. Nulla a
          nunc ex. Vivamus consectetur nulla eu pretium convallis. Duis purus
          velit, efficitur euismod velit nec, porta facilisis nisl. Mauris neque
          sapien, porta non hendrerit id, sollicitudin eu felis. Mauris sit amet
          lacus rhoncus, iaculis ligula sit amet, viverra ligula. Donec vitae
          tristique augue. Integer commodo, purus eget congue egestas, justo ex
          pellentesque ante, a congue nulla ante efficitur orci. Curabitur
          aliquam erat id dolor semper blandit. Integer semper faucibus viverra.
          Fusce nec lectus a lacus mollis mattis vitae vitae lorem. Donec
          lobortis vestibulum nunc et lacinia. Duis sem ex, pellentesque at
          bibendum ut, bibendum tristique diam. Mauris euismod consequat dolor
          quis sollicitudin. Donec pretium quis orci a sollicitudin.
        </p>
      </div>
    </section>
    <Footer />
    <WhatsApp />
  </>
)

export default PrivacyPolicy
